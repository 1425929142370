import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, FormModifier, Input } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/form-modifier.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, FormModifier: FormModifier, Input: Input }}
        code={snippet}
        platform="react"
        gitHubLink="forms/form-modifier"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['string']}>
            <Text>
              Determines the size of the form elements and their labels.
            </Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="density" types={['string']}>
            <Text>
              Determines the density of the form elements (how close they are in
              relation to each other).
            </Text>
            <List type="unordered">
              <li>
                <code>compact</code>
              </li>
              <li>
                <code>standard</code> (default)
              </li>
            </List>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space#Margin%20Classes">
                uniform-ui-css space classes.
              </Link>
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique-enough string that appears as a data attribute in the
              rendered code and serves as a hook for automated tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
